full-calendar {
  .fc-button {
    border-radius: 0 !important;
    text-transform: capitalize !important;
    height: 36px !important;

    &:focus {
      box-shadow: none !important;
    }

    &.fc-today-button {
      width: 80px;
    }
  }

  .fc-day-today {
    background: rgba(135, 152, 173, 0.1) !important;
  }

  .fc-toolbar-title {
    font-size: 1.5em !important;
    color: var(--primary-color);
    font-weight: 600;
  }

  .fc-timeGridWeek-button,
  .fc-dayGridMonth-button,
  .fc-timeGridDay-button,
  .fc-listWeek-button {
    width: 60px;
  }

  .fc-toolbar-chunk:first-child {
    /* This width equals right toolbar chunk width. It's needed for title centering. */
    width: calc(60px * 4);
  }

  .fc-list-day-cushion,
  .fc-col-header-cell-cushion {
    color: var(--primary-color);
    font-weight: 600;
  }

  .fc-timegrid-slot-label-cushion {
    color: var(--primary-color);
  }

  .fc-list-empty {
    background-color: transparent !important;
  }

  .fc-timegrid-slot {
    height: 4em !important;
  }

  .fc-timegrid-event,
  .fc-daygrid-event,
  .fc-list-event {
    cursor: pointer;
  }

  .fc-event-main {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
  }
}
