mat-paginator {
  .mat-paginator-range-label {
    margin: 0 var(--space-m);
  }
}

mat-progress-bar {
  .mat-progress-bar-buffer {
    background-color: var(--primary-light-color);
  }
}

gymautow-base-table.table-with-edit-button,
gymautow-base-table.table-with-delete-button {
  &.full-width-table {
    th:last-child {
      width: auto;
    }
  }

  tr {
    .edit,
    .delete {
      visibility: hidden;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: var(--space-l);
      min-height: 30px;

      a {
        color: var(--highlight-color);

        span {
          font-weight: bold;
          margin-left: var(--space-xs);
        }

        mat-icon {
          font-size: 18px;
          height: 20px;
          width: 20px;
          line-height: 18px;
        }
      }
    }

    &.clickable-row {
      &:hover,
      &:focus {
        .edit,
        .delete {
          visibility: visible;
        }
      }
    }
  }
}

gymautow-base-table.notes-table,
gymautow-base-table.payment-table {
  table thead {
    background-color: var(--header-dark-color);
  }

  .mat-header-cell {
    text-transform: none;
  }
}

gymautow-base-table.payment-table td.mat-cell:last-of-type {
  text-align: left;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  text-align: right;
}

gymautow-base-table td.mat-cell:last-of-type.empty-table-message {
  text-align: center;
}

gymautow-base-table,
.base-table {
  &.monthly-discounts-table {
    .mat-header-cell {
      background-color: var(--header-dark-color);
      text-transform: none;
    }

    td.mat-cell:last-of-type:not(.empty-table-message) {
      text-align: left;
    }

    td,
    th {
      &:first-of-type {
        width: 40px;
      }
    }
  }
}
