.base-header {
  width: 100%;
  padding: var(--space-l) var(--space-xl);
  background-color: var(--primary-light-color);
  font-size: var(--h1-font-size);
  font-weight: 500;
}

.hidden {
  display: none !important;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.form-error {
  font-weight: bold;
  margin: 5px 0;
  text-align: center;
  color: var(--error-font-color);
}

.highlight {
  color: var(--highlight-color);
  font-weight: bold;
}

.table-edit-button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  mat-icon {
    font-size: 18px;
    height: 20px;
    width: 20px;
    line-height: 18px;
  }
  span {
    font-weight: bold;
    margin-left: var(--space-xs);
  }
}

.link {
  color: var(--highlight-color);
  text-decoration: none;
}

.red-highlight {
  color: var(--warn-color);
  font-weight: bold;
}

.margin-bottom-medium {
  margin-bottom: var(--space-m);
}

.margin-bottom-large {
  margin-bottom: var(--space-l);
}
