button {
  border: 0;
  cursor: pointer;
  border-radius: var(--radius);
}

button.primary {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
}

button.primary:hover:not(:disabled):not(.loading) {
  background: var(--primary-dark-color);
}

button.primary:focus {
  box-shadow: 0 0 0 8px var(--primary-light-color);
  outline: none;
}

button.medium {
  min-width: 120px;
  height: 3rem;
}

.mat-button,
.mat-flat-button,
.mat-stroked-button {
  border-radius: 0 !important;
}
