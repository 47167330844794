.mat-snack-bar-container.custom-notification {
  position: absolute;
  padding: var(--space-m);
  color: var(--primary-contrast-color);
  bottom: var(--space-m);

  &.primary-message {
    background-color: var(--highlight-color);
  }

  &.success-message {
    background-color: var(--success-color);
  }

  &.warn-message {
    background-color: var(--warn-color);
  }
}
