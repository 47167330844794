/* Custom application color and font settings */
:root {
  /* Font */
  --font-size: 14px;
  --font-family: 'Nunito', sans-serif;
  --h1-font-size: 21px;
  --h2-font-size: 14px;

  /* Styles that don't represent the brand (stole this phrase from material.io) */

  /* Base color for a body text. */
  --base-color: #000000;

  /* Background color appears behind scrollable content. */
  --base-background-color: #eff3f4;

  /* Color for a text that's not important or it should not draw attention. */
  --faint-color: hsl(0, 0%, 70%);
  --faint-dark-color: hsl(0, 0%, 45%);
  --faint-light-color: hsl(0, 0%, 85%);

  /* Font color for surface background. */
  --surface-font-color: #000000;
  /* Affect surfaces of components, such as cards, sheets, and menus */
  --surface-background-color: #ffffff;

  /* Font color for error messages. */
  --error-font-color: #ee5757;
  /* In case error is a block with a message inside. */
  --error-background-color: #ffffff;

  /* Elements spacing. */
  --space-m: 15px;
  --space-xs: calc(var(--space-m) * 0.25);
  --space-s: calc(var(--space-m) * 0.5);
  --space-l: calc(var(--space-m) * 1.5);
  --space-xl: calc(var(--space-m) * 2);
  --space-xxl: calc(var(--space-m) * 3);

  /* Brand colors */
  --primary-color: #2e384d;
  --primary-dark-color: #1b2437;
  --primary-light-color: #d8e2e4;
  --primary-light-faint-color: #8798ad;
  --primary-contrast-color: white;
  --primary-dark-contrast-color: white;
  --primary-light-contrast-color: #313b50;

  --accent-color: #ec971c;
  --accent-dark-color: #cc7e18;
  --accent-light-color: #e4c8a3;
  --accent-contrast-color: white;
  --accent-dark-contrast-color: white;
  --accent-light-contrast-color: black;

  --warn-color: #da3e33;
  --warn-dark-color: #bf3737;
  --warn-light-color: #e4746c;
  --warn-contrast-color: white;
  --warn-dark-contrast-color: white;
  --warn-light-contrast-color: black;

  --success-color: #5bb762;

  /* Util properties. Util properties must not define new values. */
  --radius: calc(var(--font-size) * 0.75);
  --radius-s: calc(var(--font-size) * 0.5);
  --border-color: var(--faint-light-color);

  /* Add your variables below, please */
  --sidenav-width: 350px;
  --placeholder-color: #949494;
  --active-nav-link-color: #070a0f;
  --header-color: #6c7382;
  --header-dark-color: #8798ad;
  --highlight-color: #3a82df;
  --success-color: #5bb762;
  --table-row-hover-color: rgba(135, 152, 173, 0.3);
  --label-color: rgba(0, 0, 0, 0.6);

  /* FullCalendar styles. */
  --fc-button-bg-color: var(--primary-light-faint-color);
  --fc-button-border-color: var(--primary-light-faint-color);
  --fc-button-active-bg-color: var(--primary-color);
  --fc-button-active-border-color: var(--primary-color);
  --fc-button-hover-bg-color: var(--primary-dark-color);
  --fc-button-hover-border-color: var(--primary-dark-color);
  --fc-neutral-bg-color: #c3cbd6;
  --fc-list-event-hover-bg-color: rgba(135, 152, 173, 0.2) !important;
}
