/* Inputs */
input[type='text'],
input[type='email'],
input[type='password'],
input[type='search'],
input[type='tel'],
input:not([type]) {
  padding: 2px;
}

mat-select {
  padding: 2px;
}

::placeholder {
  color: var(--placeholder-color);
}

mat-form-field mat-icon {
  color: var(--primary-color);
}

mat-form-field.mat-form-field-appearance-fill {
  .mat-select-arrow,
  .mat-select-value,
  .mat-select-placeholder,
  .mat-form-field-label {
    color: var(--primary-light-color);
  }

  &.mat-form-field.mat-focused {
    .mat-select-arrow,
    .mat-select-value,
    .mat-select-placeholder,
    .mat-form-field-label {
      color: var(--primary-contrast-color);
    }
  }

  .mat-select-arrow {
    color: #2e394d;
  }

  .mat-form-field-flex {
    background-color: var(--primary-dark-color);
    border-radius: 4px;
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      .mat-select-arrow,
      .mat-select-value,
      .mat-select-placeholder,
      .mat-form-field-label {
        color: var(--primary-contrast-color);
      }
    }
  }

  &.mat-form-field-disabled {
    .mat-form-field-flex {
      background-color: var(--primary-dark-color);
    }
  }

  .mat-form-field-infix {
    border: 0;
  }

  .mat-select-arrow-wrapper {
    transform: none;
  }

  .mat-form-field-underline {
    display: none;
  }
}

mat-form-field.mat-form-field-appearance-standard {
  .mat-form-field-subscript-wrapper {
    margin-top: 0;
  }

  .mat-form-field-flex {
    padding: 0.1em 0.4em;
    margin-top: 1.5em;
  }

  .mat-form-field-infix {
    border-top-width: 0.2em;
  }

  .mat-form-field-label-wrapper {
    top: -1em;
  }

  .mat-form-field-label {
    top: 1.5em;
  }

  &.mat-form-field-should-float {
    .mat-form-field-label {
      font-size: 1.3rem;
    }

    .mat-form-field-label-wrapper {
      top: -1.8em;
    }
  }

  &.mat-focused {
    .mat-form-field-required-marker {
      color: var(--primary-color);
    }

    .mat-form-field-flex {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .mat-select-arrow-wrapper {
    transform: translateY(-1px) !important;
  }

  .mat-form-field-prefix {
    top: 2px;
  }
}

mat-form-field.currency__input {
  .mat-form-field-prefix {
    top: -2px;
  }
}

mat-radio-button.mat-primary {
  .mat-radio-outer-circle {
    border-width: 3px;
    border-color: var(--primary-light-faint-color);
  }
}

/** Override input styles to hide arrows. */
.no-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    /* We need this to hide arrows. */
    -moz-appearance: textfield;
  }
}
