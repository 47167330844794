label {
  display: inline-block;
}

label > input,
label > select,
label > textarea,
label > span + input,
label > span + select,
label > span + textarea {
  width: 100%;
}

.form-control-label {
  display: block;
  padding-left: 6px;
  margin-bottom: var(--space-s);
  color: rgba(0, 0, 0, 0.6);

  &.required::after {
    content: ' *';
    vertical-align: top;
  }

  &.error {
    color: var(--warn-color);
  }
}

.no-wrap {
  white-space: nowrap;
}